import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { pageFragments } from '../fragments/pagePreview';
import Page from '../templates/page';

const preview = () => {
  const [weddingsPage, setWeddingsPage] = useState(null);
  const isBrowser = typeof window !== 'undefined';
  const queryParams = new URLSearchParams(isBrowser && window.location.search);
  const [message, setMessage] = useState('Loading Preview');
  const langCode = queryParams.get('lang') || 'en-US';
  const id = queryParams.get('id');

  useEffect(() => {
    const loadPage = (type) => {
      let q = '';
      switch (type) {
        case 'weddingsPage':
          q = `
            ${pageFragments}
            query ($id: ID!, $lang: String!) {
              weddingsPage(id: $id, culture: $lang, preview: true) {
                  ...Page
                }
            }
          `;
          break;
        default:
          setMessage('Invalid document type');
          return;
      }
      const options = {
        method: 'POST',
        url: 'https://graphql.umbraco.io',
        headers: {
          'Content-Type': 'application/json',
          'Umb-Project-Alias': process.env.GATSBY_UMBRACO_ENVIRONMENT,
          'Api-Key': process.env.GATSBY_PREVIEW_API_KEY,
        },
        data: JSON.stringify({
          query: q,
          variables: {
            id,
            lang: langCode,
          },
        }),
      };
      axios
        .request(options)
        .then((response) => {
          if (type === 'weddingsPage') {
            setWeddingsPage({
              umbraco: { weddingsPage: response.data.data.weddingsPage },
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    const getPageType = async () => {
      const options = {
        method: 'POST',
        url: 'https://graphql.umbraco.io',
        headers: {
          'content-type': 'application/json',
          'Umb-Project-Alias': process.env.GATSBY_UMBRACO_ENVIRONMENT,
          'Api-Key': process.env.GATSBY_PREVIEW_API_KEY,
        },
        data: {
          query: `
                        query ($id: ID!, $lang: String!) {
                           content(id: $id, culture: $lang, preview: true) {
                              contentTypeAlias
                            }
                        }
                        `,
          variables: {
            id,
            lang: langCode,
          },
        },
      };
      axios
        .request(options)
        .then((response) => {
          loadPage(response.data.data.content.contentTypeAlias);
        })
        .catch(() => {
          // console.error(error);
        });
    };

    if (!id) {
      setMessage('No id found');
      return;
    }
    loadPage('weddingsPage');
  }, []);
  return (
    <>
      {weddingsPage ? (
        <Page
          pageContext={{ langCode: langCode.split('-')[0], lang: langCode }}
          data={weddingsPage}
        />
      ) : (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', hight: '100%' }}
        >
          <h1>{message}</h1>
        </div>
      )}
    </>
  );
};

export default preview;
